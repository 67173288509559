import { render, staticRenderFns } from "./DomainsDialog.vue?vue&type=template&id=209865a2&scoped=true&lang=pug&"
import script from "./DomainsDialog.vue?vue&type=script&lang=js&"
export * from "./DomainsDialog.vue?vue&type=script&lang=js&"
import style0 from "./DomainsDialog.vue?vue&type=style&index=0&id=209865a2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "209865a2",
  null
  
)

export default component.exports