<template lang="pug">
  .progress-saved-dialog(ref="dialog")
    NioIconFramer(
      iconName="display-notification"
    )
    h3.nio-h3.text-primary-darker Changes saved!
    p.nio-p.text-primary-dark You may visit your Data Shop in a few minutes to verify that your changes are correct.
    .actions
      NioButton(
        normal-secondary
        @click="close"
      ) Ok
</template>

<script>


export default {
  methods: {
    close() {
      this.$emit('close')
    }
  },
  components: { }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.progress-saved-dialog
  padding: 78px 24px 54px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 16px
    text-align: center
  p 
    margin-bottom: 54px
    text-align: center
    max-width: 500px
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 16px  
</style>