<template lang="pug">
  .domains-dialog
    .app-loading(v-if="loadingManifest")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .header
      h2.nio-h2.text-primary-darker Choose which Data Shop to load
    NioSlatTable(
      v-if="domains && headers"
      :items="domains"
      :columns="headers"
      footer-actions
      single-select
      @selectionChanged="selectionChanged($event)"
    )
      template(v-slot:footer-actions)
        NioButton(
          normal-secondary 
          @click="createNew"
        ) Create a new Data Shop
    .actions
      NioButton(
        :disabled="!selection"
        normal-primary 
        @click="loadManifest"
      ) Continue
</template>

<script>

import { mapActions } from 'vuex'
import messagesModule from '@/modules/messages'
import DefaultSiteManifest from '@/modules/defaultSiteManifest'

export default {
  props: {
    "domains": { type: Array, required: true }
  },
  data: () => ({
    headers: null,
    selection: null,
    loadingManifest: false
  }),	
  mounted() {
    this.computeHeaders()
  },
  methods: {
    ...mapActions(['setSiteManifest', 'setDomainProperties']),
    computeHeaders() {
      this.headers = [
        {
          name: "slat",
          props: {
            title: 'name',
            image: this.computeImage
          }
        }
      ]
    },
    computeImage() {
      return 'https://cdn.narrative.io/data-studio/images/narrative-placeholder-primary.svg'
    },
    selectionChanged(item) {
      this.selection = item
    },
    createNew() {
      this.setSiteManifest(DefaultSiteManifest)
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.$emit('createNew')
    },
    dataShopManifestFetched(manifest) {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.loadingManifest = false
      if (manifest && (manifest.companyId === this.nioUser.companyId || this.nioUser.role === 99)) {
        this.setSiteManifest(manifest)
        this.$emit('loadManifestSuccess', true)
      } else {
        this.$emit('loadManifestSuccess', false)
      }
    },
    loadManifest() {
      this.loadingManifest = true
      messagesModule.addListener('dataShopManifestFetched', this.dataShopManifestFetched)
      parent.postMessage({
        name: 'fetchDataShopManifest',
        payload: this.selection.name
      },"*")
    }
  }
}
</script>

<style lang="sass" scoped>
  .domains-dialog
    padding: 8px
    .header
      display: flex
      justify-content: flex-start
      align-items: flex-start
      position: relative
      margin-bottom: 32px
      h2
        margin: 0px
    .nio-slat-table
      margin-bottom: 24px
    .actions
      width: 100%
      display: flex
      justify-content: flex-end
      margin-top: 8px
</style>