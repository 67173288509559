export default {
	url: null,
	companyId: null,
	preventRegistration: false,
	config: {
		ga: null,
		gtm: null,
		shopName: null,
		description: null,
		tags: []
	},
	emailTemplate: {
		companyName: null,
		logo: null
	},
	global: {
		logo: null,
		logoAlt: null,
		colors: {
			primaryLightest: "#E5E0E0",
			primaryLighter: "#DED8D8",
			primaryLight: "#CDC5C5",
			primary: '#1F39A9',
			primaryDark: "#454444",
			primaryDarker: "#212121",
			primaryDarkest: "#000000",
			canvas: "#F1EDED",
			canvasDarker: "#ECE8E8",
			pageBackground: "#F1EDED"
		}
	},
	hero: {
		headline: {
			text: null,
			color: '#FFFFFF'
		},
		tagline: {
			text: null,
			color: '#DED8D8'
		},
		backgroundColor: "#282F65",
		backgroundImage: null
	},
	navbar: {
		backgroundColor: "#1A1A1A",
		linkColor: "#CDC5C5",
		linkText: {
			home: 'Home'
		},
		icons: {
			color: '#CDC5C5',
			activeColor: '#FFFFFF'
		}
	},
	footer: {
		backgroundColor: '#FFFFFF'
	},
	pages: {
		home: {
			heading: null,
			subheading: null,
			hero: {
				headline: {
					text: null,
					color: '#FFFFFF'
				},
				tagline: {
					text: null,
					color: '#DED8D8'
				},
				backgroundColor: "#282F65",
				backgroundImage: null
			}
		},
		productDetail: {
			bannerBackgroundColor: "#454444"
		},
		loginRegister: {
			backgroundColor: '#FFFFFF',
			backgroundTextColor: '#D17300'
		}
	},
	supportEmail: null
}

