<template lang="pug">
  .home-page-step
    .sub-container
      .hero-background-color.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Hero Graphic
          .description.nio-p.text-primary-dark The main banner on your Data Shop homepage.
        .filter-value
          NioTextField(
            v-model="model.heroHeadlineText"
            label="Headline"
          )
          NioColorPicker(
            v-model="model.heroHeadlineColor"
            label="Headline Text Color"
          )
          NioTextField(
            v-model="model.heroTaglineText"
            label="Tagline"
          )
          NioColorPicker(
            v-model="model.heroTaglineColor"
            label="Tagline Text Color"
          )
          NioColorPicker(
            v-model="model.heroBackgroundColor"
            label="Hero Background Color"
          )
    .sub-container
      .heading.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Page Heading
          .description.nio-p.text-primary-dark The text of the page heading on your Data Shop homepage.
        .filter-value
          NioTextField(
            v-model="model.heading"
            label="Page Heading"
          )
      .subheading.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Page Subheading
          .description.nio-p.text-primary-dark The text of the page subheading on your Data Shop homepage.
        .filter-value
          NioTextField(
            v-model="model.subheading"
            label="Page Subheading"
          )
      .background.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Main Background
          .description.nio-p.text-primary-dark Background color of the Home page.
        .filter-value
          NioColorPicker(
            v-model="model.pageBackground"
            label="Main Background Color"
          )
      
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    loading: false,
    model: {
      pageBackground: '#000000',
      heading: null,
      subheading: null,
      heroHeadlineText: null,
      heroHeadlineColor: '#000000',
      heroTaglineText: null,
      heroTaglineColor: '#000000',
      heroBackgroundColor: '#000000'
    }
  }),
  computed: {
    ...mapGetters(['siteManifest'])
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('stepUpdated', val)
      }
    },
    siteManifest: {
      deep: true,
      handler() {
        this.updateModel()
      }
    }
  },
  mounted() {
    this.updateModel()
  },
  methods: {
    updateModel() {
      const m = this.siteManifest
      this.model = {
        pageBackground: m.pages && m.pages.home && m.pages.home.pageBackground ? m.pages.home.pageBackground : m.global.colors.pageBackground,
        heading: m.pages && m.pages.home ? m.pages.home.heading : '',
        subheading: m.pages && m.pages.home ? m.pages.home.subheading : '',
        heroHeadlineText: m.pages && m.pages.home && m.pages.home.hero && m.pages.home.hero.headline ? m.pages.home.hero.headline.text : null,
        heroHeadlineColor: m.pages && m.pages.home && m.pages.home.hero && m.pages.home.hero.headline && m.pages.home.hero.headline.color ? m.pages.home.hero.headline.color : '#FFFFFF',
        heroTaglineText: m.pages && m.pages.home && m.pages.home.hero && m.pages.home.hero.tagline ? m.pages.home.hero.tagline.text : null,
        heroTaglineColor: m.pages && m.pages.home && m.pages.home.hero && m.pages.home.hero.tagline && m.pages.home.hero.tagline.color ? m.pages.home.hero.tagline.color : m.global.colors.primaryLight,
        heroBackgroundColor: m.pages && m.pages.home && m.pages.home.hero ? m.pages.home.hero.backgroundColor : m.global.colors.primaryDark
      }
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.home-page-step
  .filter
    .title-description
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-color-picker
        width: 100%
        margin-bottom: 24px
        margin-top: 0px
</style>
