<template lang="pug">
  .login-register-page-step
    .sub-container
      .background-color.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Background Color
          .description.nio-p.text-primary-dark The background color of the Login and Register pages of your Data Shop.
        .filter-value
          NioColorPicker(
            v-model="model.backgroundColor"
            label="Background Color"
          )
      .background-text-color.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Background Text Color
          .description.nio-p.text-primary-dark The text color of the Login and Register pages of your Data Shop.
        .filter-value
          NioColorPicker(
            v-model="model.backgroundTextColor"
            label="Background Text Color"
          )
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    loading: false,
    model: {
      backgroundColor: '#000000',
      backgroundTextColor: '#000000'
    }
  }),
  computed: {
    ...mapGetters(['siteManifest'])
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('stepUpdated', val)
      }
    },
    siteManifest: {
      deep: true,
      handler() {
        this.updateModel()
      }
    }
  },
  mounted() {
    this.updateModel()
  },
  methods: {
    updateModel() {
      const m = this.siteManifest
      this.model = {
        backgroundColor: m.pages && m.pages.loginRegister && m.pages.loginRegister.backgroundColor ? m.pages.loginRegister.backgroundColor : m.global.colors.primaryDarker,
        backgroundTextColor: m.pages && m.pages.loginRegister && m.pages.loginRegister.backgroundTextColor ? m.pages.loginRegister.backgroundTextColor : m.global.colors.primaryLighter
      }
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.login-register-page-step
  .filter
    .title-description
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-tags-field
        width: 100%
</style>
