<template lang="pug">
  .site-pages-page
    NioDialog(
      v-model="savedDialog"
    )
      SavedDialog(@close="savedDialog = false")
    NioDialog(
      v-model="showDomainsDialog"
    )
      DomainsDialog(
        :domains="domains"
        @close="domainsDialog = false"
        @manifestLoaded="manifestLoaded($event)"
        @success="manifestLoaded"
        @createNew="createNewDataShop"
        @loadManifestSuccess="loadManifestSuccessUpdated($event)"
      )
    NioDialog(
      v-model="manifestLoadedDialog"
    )
      ManifestLoadedDialog(
        :success="loadManifestSuccess"
        @close="closeManifestLoadedDialog"
      )
    .app-loading(v-if="loading || saving")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .header.app-header
      h1.nio-h1.text-primary-darker Customize Site Pages
      NioButton(
        icon-name="utility-external-link"
        normal-secondary-append
        @click="launchPreview"
      ) Preview Site
    .customize
      NioTabs(
        v-if="siteManifest && !loading && !saving"
        v-model="activeTab"
        :tabs="tabs"
      )
        template(
          v-slot:global
        )
          .tab-content
            GlobalStep(
              @stepUpdated="updateStep('global', $event)"
            )
        template(
          v-slot:home
        )
          .tab-content
            HomePageStep(
              @stepUpdated="updateStep('home', $event)"
            )
        template(
          v-slot:product
        )
          .tab-content
            ProductDetailPageStep(
              @stepUpdated="updateStep('product', $event)"
            )
        template(
          v-slot:loginRegister
        )
          .tab-content
            LoginRegisterPageStep(
              @stepUpdated="updateStep('loginRegister', $event)"
            )
        template(
          v-slot:menu
        )
          .tab-content
            MenuStep(
              @stepUpdated="updateStep('menu', $event)"
            )
      NioButton(
        v-if="siteManifest && !loading"
        normal-primary
        @click="save"
      ) Save Settings
</template>

<script>

import DomainsDialog from '@/shared/components/DomainsDialog'
import GlobalStep from './steps/GlobalStep'
import HomePageStep from './steps/HomePageStep'
import ProductDetailPageStep from './steps/ProductDetailPageStep'
import LoginRegisterPageStep from './steps/LoginRegisterPageStep'
import MenuStep from './steps/MenuStep'
import { mapGetters, mapActions } from 'vuex'
import DefaultSiteManifest from '@/modules/defaultSiteManifest'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import messagesModule from '@/modules/messages'
import SavedDialog from '@/components/SavedDialog'
import ManifestLoadedDialog from '@/shared/components/ManifestLoadedDialog'

export default {
  components: { DomainsDialog, ManifestLoadedDialog, GlobalStep, HomePageStep, ProductDetailPageStep, LoginRegisterPageStep, MenuStep, SavedDialog },
  data: () => ({
    domains: null,
    showDomainsDialog: false,
    loadManifestSuccess: false,
    manifestLoadedDialog: false,
    loading: true,
    saving: false,
    savedDialog: false,
    tabs: [
      {
        name: 'global',
        label: 'Global'
      },
      {
        name: 'home',
        label: 'Homepage'
      },
      {
        name: 'product',
        label: 'Product Detail'
      },
      {
        name: 'loginRegister',
        label: 'Login/Register'
      },
      {
        name: 'menu',
        label: 'Main Nav'
      }
    ],
    payloads: {
      global: null,
      home: null,
      product: null, 
      loginRegister: null,
      menu: null
    },
    activeTab: 0
  }),	
  mounted() {
    localStorage.removeItem('nio:shop-builder-bypass')
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  computed: {
    ...mapGetters(['siteManifest']),
    activeTabValid() {
      return true
    }
  },
  methods: {
    ...mapActions([
      'setSiteManifest', 
      'setGlobalColors', 
      'setHomePageProperties',
      'setProductDetailPageProperties',
      'setLoginRegisterProperties',
      'setMenuProperties'
    ]),
    openApiInit() {
      this.getCompanyDomains().then(domains => {
        this.domains = domains.map((domain, index) => {
          return {
            id: index,
            name: domain
          }
        })
        if (domains && domains.length > 0) {
          this.showDomainsDialog = true
        } else {
          this.createNewDataShop()
        }
        this.loading = false
      })
    },
    getCompanyDomains() {
      return new Promise((resolve, reject) => {
        this.$nioOpenApi.get(`/company-info/${this.nioUser.companyId}/domains`).then(res => {
          resolve(res.data.domains)
        })
      })
    },
    manifestLoaded(manifest) {
      this.showDomainsDialog = false
    },
    loadManifestSuccessUpdated(val) {
      this.loadManifestSuccess = val
      this.showDomainsDialog = false
      this.manifestLoadedDialog = true
    },
    closeManifestLoadedDialog() {
      this.manifestLoadedDialog = false
      if (!this.loadManifestSuccess) {
        this.showDomainsDialog = true
      }
    },
    updateStep(stepName, payload) {
      this.tabs.find(tab => tab.name === stepName).payload = payload
    },
    save() {
      this.saving = true
      const step = this.tabs[this.activeTab]
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      switch (step.name) {
        case 'global':
          this.setGlobalColors(step.payload)
          break;
        case 'home':
          this.setHomePageProperties(step.payload)
        case 'product':
          this.setProductDetailPageProperties(step.payload)
          break;
        case 'loginRegister':
          this.setLoginRegisterProperties(step.payload)
          break;
        case 'menu':
          this.setMenuProperties(step.payload)
          break;
        default:
          break;
      }
      messagesModule.addListener('dataShopManifestSaved', this.dataShopManifestSaved)
      parent.postMessage({
        name: 'saveDataShopManifest',
        payload: this.siteManifest
      },"*")
    },
    dataShopManifestSaved() {
      this.saving = false
      this.savedDialog = true
    },
    launchPreview() {
      parent.postMessage({
        name: 'launchDataShopPreview',
        payload: JSON.stringify(this.siteManifest)
      },"*")
    },
    createNewDataShop() {
      this.showDomainsDialog = false
      localStorage.setItem('nio:shop-builder-bypass', true)
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'shop-settings'
      },"*")
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.site-pages-page
  padding: 24px
  .header
    margin-bottom: 24px
    display: flex
    justify-content: space-between
  .customize
    .tab-content
      overflow: hidden
    .nio-button
      margin: 24px auto 0px auto    
  ::v-deep h2
    margin-top: 24px
    margin-bottom: 16px
  ::v-deep .v-slide-group__content.v-tabs-bar__content
    justify-content: flex-start !important
 
</style>