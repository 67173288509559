<template lang="pug">
  .global-step
    .sub-container
      .page-background.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker PageBackground
          .description.nio-p.text-primary-dark The default background color for all pages. Backgrounds for certain individual pages can be specified on the subsequent steps and will override this value.
        .filter-value
          NioColorPicker(
            v-model="model.pageBackground"
            label="Page Background Color"
          )
      .canvas.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Canvas
        .filter-value
          NioColorPicker(
            v-model="model.canvas"
            label="Canvas Color"
          )
      .canvas-darker.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker CanvasDarker
        .filter-value
          NioColorPicker(
            v-model="model.canvasDarker"
            label="CanvasDarker Color"
          )
    .sub-container
      .primary-base.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Primary Palette
          .description.nio-p.text-primary-dark Set the base color palette for your Data Shop.
        .filter-value
          NioColorPicker(
            v-model="model.primary"
            label="Primary Color"
          )
          NioColorPicker(
            v-model="model.primaryLightest"
            label="PrimaryLightest Color"
          )
          NioColorPicker(
            v-model="model.primaryLighter"
            label="PrimaryLighter Color"
          )
          NioColorPicker(
            v-model="model.primaryLight"
            label="PrimaryLight Color"
          )
          NioColorPicker(
            v-model="model.primaryDark"
            label="PrimaryDark Color"
          )
          NioColorPicker(
            v-model="model.primaryDarker"
            label="PrimaryDarker Color"
          )
          NioColorPicker(
            v-model="model.primaryDarkest"
            label="PrimaryDarkest Color"
          )   
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
  },
  data: () => ({
    loading: false,
    model: {
      primaryLightest: '#000000',
      primaryLighter: '#000000',
      primaryLight: '#000000',
      primary: '#000000',
      primaryDark: '#000000',
      primaryDarker: '#000000',
      primaryDarkest: '#000000',
      canvas: '#000000',
      canvasDarker:'#000000',
      pageBackground: '#000000'
    }
  }),
  computed: {
    ...mapGetters(['siteManifest'])
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('stepUpdated', val)
      }
    },
    siteManifest: {
      deep: true,
      handler() {
        this.updateModel()
      }
    }
  },
  mounted() {
    this.updateModel()
  },
  methods: {
    updateModel() {
      this.model = {
        primaryLightest: this.siteManifest.global.colors.primaryLightest,
        primaryLighter: this.siteManifest.global.colors.primaryLighter,
        primaryLight: this.siteManifest.global.colors.primaryLight,
        primary: this.siteManifest.global.colors.primary,
        primaryDark: this.siteManifest.global.colors.primaryDark,
        primaryDarker: this.siteManifest.global.colors.primaryDarker,
        primaryDarkest: this.siteManifest.global.colors.primaryDarkest,
        canvas: this.siteManifest.global.colors.canvas,
        canvasDarker: this.siteManifest.global.colors.canvasDarker,
        pageBackground: this.siteManifest.global.colors.pageBackground
      }
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.global-step
  .filter
    .title-description
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-tags-field
        width: 100%
    &.primary-base .nio-color-picker
      width: 100%
      margin-bottom: 24px
      margin-top: 0px
</style>
