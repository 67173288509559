<template lang="pug">
  .product-detail-page-step
    .sub-container
      .background.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Banner Background
          .description.nio-p.text-primary-dark The background color of a Data Stream in your shop.
        .filter-value
          NioColorPicker(
            v-model="model.bannerBackground"
            label="Banner Background Color"
          )
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    loading: false,
    model: {
      bannerBackground: '#000000'
    }
  }),
  computed: {
    ...mapGetters(['siteManifest'])
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('stepUpdated', val)
      }
    },
    siteManifest: {
      deep: true,
      handler() {
        this.updateModel()
      }
    }
  },
  mounted() {
    this.updateModel()
  },
  methods: {
    updateModel() {
      const m = this.siteManifest
      this.model = {
        bannerBackground: m.pages && m.pages.productDetail && m.pages.productDetail.bannerBackground ? m.pages.productDetail.bannerBackground : m.global.colors.primaryDark
      }
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.product-detail-page-step
  .filter
    .title-description
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-tags-field
        width: 100%
</style>
