<template lang="pug">
  .manifest-loaded-dialog(ref="dialog")  
    template(v-if="success")
      NioIconFramer(
        icon-name="display-notification"
      )
      h3.nio-h3.text-primary-darker Data Shop Loaded
      p.nio-p.text-primary-dark We have retreived the current version of your Data Shop succesfully. You can now make changes to update your Data Shop.
      .actions
        NioButton(
          normal-secondary
          @click="close"
        ) Ok
    template(v-else)
      NioIconFramer(
        icon-name="display-warning"
      )
      h3.nio-h3.text-primary-darker Something went wrong...
      p.nio-p.text-primary-dark We don't have a record of a data shop registered to that domain. Please enter another domain. If you continue to experience problems, please contact a member of the Narrative support team.
      .actions
        NioButton(
          normal-secondary
          @click="close"
        ) Back
</template>

<script>


export default {
  props: ['success'],
  methods: {
    close() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.manifest-loaded-dialog
  padding: 78px 24px 54px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 16px
    text-align: center
  p 
    margin-bottom: 54px
    text-align: center
    max-width: 500px
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 16px  
</style>