<template lang="pug">
  .menu-step
    .sub-container
      .navbar-background.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Navbar Background Color
          .description.nio-p.text-primary-dark The background color of the navbar and mobile nav menu.
        .filter-value
          NioColorPicker(
            v-model="model.background"
            label="Navbar Background Color"
          )
      .navlink-color.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Navlink Color
          .description.nio-p.text-primary-dark The color of the navigation links within the navbar and mobile nav menu.
        .filter-value
          NioColorPicker(
            v-model="model.linkColor"
            label="Navlink Color"
          )
    .sub-container
      .icon.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Icon Color
          .description.nio-p.text-primary-dark The default color of icons in the navbar.
        .filter-value
          NioColorPicker(
            v-model="model.icon"
            label="Icon Color"
          )
      .active-icon.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Active Icon Color
          .description.nio-p.text-primary-dark This color will be displayed when the page associated with the icon is the current page.
        .filter-value
          NioColorPicker(
            v-model="model.iconActive"
            label="Active Icon Color"
          )
    .sub-container
      .home-text.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Home Page Navlink Text
          .description.nio-p.text-primary-dark The text that will be displayed for the navlink associated with the Home page
        .filter-value
          NioTextField(
            v-model="model.homeLinkText"
            label="Home Page Navlink Text"
          )
        
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    loading: false,
    model: {
      background: '#000000',
      linkColor: '#000000',
      icon: '#000000',
      iconActive: '#000000',
      homeLinkText: null
    }
  }),
  computed: {
    ...mapGetters(['siteManifest'])
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('stepUpdated', val)
      }
    },
    siteManifest: {
      deep: true,
      handler() {
        this.updateModel()
      }
    }
  },
  mounted() {
    this.updateModel()
  },
  methods: {
    updateModel() {
      const m = this.siteManifest
      this.model = {
        background: m.navbar && m.navbar.backgroundColor ? m.navbar.backgroundColor : m.global.colors.primaryDarker,
        linkColor: m.navbar && m.navbar.linkColor ? m.navbar.linkColor : m.global.colors.primaryDarkest,
        icon: m.navbar && m.navbar.icons && m.navbar.icons.color ? m.navbar.icons.color : '#CDC5C5',
        iconActive: m.navbar && m.navbar.icons && m.navbar.icons.activeColor ? m.navbar.icons.activeColor : '#FFFFFF',
        homeLinkText: m.navbar && m.navbar.linkText && m.navbar.linkText.home ? m.navbar.linkText.home : 'Home'
      }
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.menu-step
  .filter
    .title-description
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-tags-field
        width: 100%
</style>
